
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    tag: {
      type: String,
      required: true,
      default: "---",
    },
  },
  methods: {
    redirectToTag: function (tag: string) {
      this.$router.push({
        name: "Articles",
        query: {
          tags: tag,
        },
      });
    },
  },
})
export default class SingleArticlePageTagItem extends Vue {
  tag!: string;
}
