<template>
  <span class="tag-search-text" @click="redirectToTag(tag)">
    {{ tag }}
  </span>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    tag: {
      type: String,
      required: true,
      default: "---",
    },
  },
  methods: {
    redirectToTag: function (tag: string) {
      this.$router.push({
        name: "Articles",
        query: {
          tags: tag,
        },
      });
    },
  },
})
export default class SingleArticlePageTagItem extends Vue {
  tag!: string;
}
</script>

<style scoped>
.tag-search-text {
  @apply relative bg-gray-300 px-4 py-2 gap-y-2 mx-1 rounded-md whitespace-nowrap cursor-pointer;
}
</style>
